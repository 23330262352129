<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot:default="{ disabled }">
      <pp-card-body class="pt-1">
        <div>
          <a
            v-for="source in tokenIssuer.yieldBearingToken.sources"
            :key="source"
            :href="source"
            target="_blank"
            class="inline-flex items-center text-sm outline-none focus:ring-2 ring-offset-4 ring-water-600 rounded underline transition"
          >
            Get {{ tokenIssuer.yieldBearingToken.symbol }}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ml-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        </div>

        <div v-if="userNote" class="mt-5">
          <tip-card title="Note">{{ userNote }}</tip-card>
        </div>
      </pp-card-body>

      <hr />

      <pp-card-body>
        <pp-token-amount-input
          :value="inputAmount"
          @input="handleInput"
          show-token
          autocomplete="off"
          :address="tokenIssuer.yieldBearingToken.address"
          :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
          :disabled="market.statusIs('inactive')"
        ></pp-token-amount-input>

        <div class="mt-4">
          <span>Expiry</span>
          <pp-formatted-date
            data-test="expiry"
            class="font-semibold inline-block ml-3 py-1 px-3 border border-p-blue-300 rounded-lg"
            :value="tokenIssuer.expiresAt"
          ></pp-formatted-date>
        </div>
      </pp-card-body>

      <template v-if="outputTokenAmounts.length > 0">
        <hr />

        <pp-card-body>
          <pp-simple-data>
            <template #left>Output</template>
            <template #right>
              <LoadingIcon v-if="mintDetailsState === 'loading'" class="h-5 w-5" />
            </template>
          </pp-simple-data>

          <div class="mt-2 space-y-4">
            <OutputTokenAmount
              data-test="output"
              v-for="tokenAmount in outputTokenAmounts"
              :key="tokenAmount.token.address"
              :address="tokenAmount.token.address"
              :amount="tokenAmount.formattedAmount()"
            />
          </div>
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-token-approve-container
          :spender-address="tokenIssuer.address"
          :spending-amount="mintAmount"
          :token-address="tokenIssuer.yieldBearingToken.address"
        >
          <template v-slot:default="{ approved }">
            <pp-btn
              v-if="approved"
              size="xl"
              class="flex w-full"
              type="submit"
              :disabled="disabled || market.statusIs('inactive')"
              :loading="form.is('loading')"
              data-test="btn"
            >
              Mint
            </pp-btn>
          </template>
        </pp-token-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import TipCard from '@/components/TipCard.vue'
  import TokenIssuer from '@/domains/entities/TokenIssuer'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import OutputTokenAmount from '@/components/OutputTokenAmount.vue'
  import LoadingIcon from '@/components/svg/LoadingIcon.vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'

  const debounceTime = 500

  export default Vue.extend({
    props: {
      tokenIssuerId: { type: String, required: true },
    },
    components: { TipCard, OutputTokenAmount, LoadingIcon },
    data() {
      return {
        inputAmount: '',
        outputTokenAmounts: [],
        timeout: null,
        mintDetailsState: 'idle',
        form: createState(),
      }
    },
    computed: {
      tokenIssuer() {
        return TokenIssuer.query().find(this.tokenIssuerId)
      },
      userNote() {
        const lendingProtocols = ['Compound', 'Aave-v2', 'BENQI']
        const { protocol, symbol } = this.tokenIssuer.yieldBearingToken

        return lendingProtocols.includes(protocol)
          ? `${symbol} used for minting will not be eligible as collateral for borrowing on ${protocol}. Mintable balance is the maximum allowed before existing loans are liquidated.`
          : null
      },
      mintAmount() {
        return new TokenAmount(
          this.tokenIssuer.yieldBearingToken,
          this.inputAmount.length > 0 ? this.inputAmount : '0',
          false
        )
      },
      market() {
        const { tokenAddress1, tokenAddress2 } = this.$route.params

        return PendleAmmMarket.query().findBy('pair', (val) =>
          [tokenAddress1, tokenAddress2].every((addr) => val.includes(addr))
        )
      },
    },
    methods: {
      handleInput(value) {
        this.inputAmount = value
        this.mintDetailsState = 'loading'

        clearTimeout(this.timeout)

        setTimeout(() => {
          this.tokenIssuer
            .contract(this.$store.getters['wallet/identity'])
            .mintDetails(this.mintAmount)
            .then((response) => {
              if (this.inputAmount === value) {
                this.mintDetailsState = 'resolved'
                this.outputTokenAmounts = response
              }
            })
        }, debounceTime)
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.tokenIssuer.contract(this.$store.getters['wallet/identity']).mint(this.mintAmount),
          this.form
        )
          .execute()
          .then((response) => {
            const {
              inputAmount,
              tokenIssuer: { ot, yieldBearingToken },
            } = this
            const outputAmount = this.outputTokenAmounts[0].formattedAmount()
            const expiryText = ot.name.split('-').pop()
            this.$notification.success({
              title: 'Mint',
              text: `Minted ${formatNumber(
                outputAmount
              )} YT and OT (${expiryText}) with ${formatNumber(inputAmount)} ${
                yieldBearingToken.symbol
              }.`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Mint',
              text: `Unable to mint ${this.tokenIssuer.ot.symbol} & ${this.tokenIssuer.yt.symbol}`,
            })
          })
      },
    },
  })
</script>
