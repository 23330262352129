<template>
  <MintingForm :token-issuer-id="tokenIssuer.id" :key="successCount" @success="successCount++" />
</template>

<script>
  import Vue from 'vue'
  import TokenIssuer from '@/domains/entities/TokenIssuer'
  import MintingForm from '@/components/forms/MintingForm.vue'

  export default Vue.extend({
    components: { MintingForm },
    data() {
      return {
        successCount: 0,
      }
    },
    computed: {
      tokenIssuer() {
        const { tokenAddress1, tokenAddress2 } = this.$route.params
        return TokenIssuer.query().findBy('ytAddress', [tokenAddress1, tokenAddress2])
      },
    },
  })
</script>
